// @generated

import { z } from 'zod';

export type TroonWebSignupDto = z.infer<typeof TroonWebSignupDto>;
export const TroonWebSignupDto = z.object({});

export type TroonWebUpdateUserDto = z.infer<typeof TroonWebUpdateUserDto>;
export const TroonWebUpdateUserDto = z.object({});

export type OperatorSignupBodyDto = z.infer<typeof OperatorSignupBodyDto>;
export const OperatorSignupBodyDto = z.object({
	email: z.string(),
	password: z.string(),
	firstName: z.string(),
	lastName: z.string(),
});

export type AuthOperatorDto = z.infer<typeof AuthOperatorDto>;
export const AuthOperatorDto = z.object({
	id: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	email: z.string(),
});

export type HTTPErrorDto = z.infer<typeof HTTPErrorDto>;
export const HTTPErrorDto = z.object({
	displayMessage: z.string(),
	fields: z
		.union([
			z.array(
				z.object({
					field: z.string(),
					displayMessage: z.string(),
				}),
			),
			z.undefined(),
		])
		.optional(),
});

export type OperatorLoginBodyDto = z.infer<typeof OperatorLoginBodyDto>;
export const OperatorLoginBodyDto = z.object({
	email: z.string(),
	password: z.string(),
});

export type OperatorLoginResponseDto = z.infer<typeof OperatorLoginResponseDto>;
export const OperatorLoginResponseDto = z.object({
	accessToken: z.string(),
	refreshToken: z.string(),
	operator: z.object({
		id: z.string(),
		firstName: z.string(),
		lastName: z.string(),
		email: z.string(),
	}),
});

export type SuccessResponseDto = z.infer<typeof SuccessResponseDto>;
export const SuccessResponseDto = z.object({
	success: z.boolean(),
});

export type ForgotPasswordBodyDto = z.infer<typeof ForgotPasswordBodyDto>;
export const ForgotPasswordBodyDto = z.object({
	email: z.string(),
});

export type ForgotPasswordDto = z.infer<typeof ForgotPasswordDto>;
export const ForgotPasswordDto = z.object({
	email: z.string(),
	expirationInMinutes: z.number(),
});

export type ResetPasswordBodyDto = z.infer<typeof ResetPasswordBodyDto>;
export const ResetPasswordBodyDto = z.object({
	email: z.string(),
	password: z.string(),
	confirmationCode: z.string(),
});

export type ChangePasswordDto = z.infer<typeof ChangePasswordDto>;
export const ChangePasswordDto = z.object({
	oldPassword: z.string(),
	newPassword: z.string(),
});

export type FullOperatorInviteDto = z.infer<typeof FullOperatorInviteDto>;
export const FullOperatorInviteDto = z.object({
	id: z.string(),
	email: z.string(),
	createdAt: z.string(),
	expiresAt: z.string(),
	facility: z.object({
		id: z.string(),
		name: z.string(),
		slug: z.string(),
		logo: z.union([z.string(), z.null(), z.undefined()]).optional(),
		currencyCode: z.union([
			z.literal('AED'),
			z.literal('AUD'),
			z.literal('AZN'),
			z.literal('BGN'),
			z.literal('BHD'),
			z.literal('BSD'),
			z.literal('CAD'),
			z.literal('CHF'),
			z.literal('CRC'),
			z.literal('CZK'),
			z.literal('EGP'),
			z.literal('EUR'),
			z.literal('GBP'),
			z.literal('HNL'),
			z.literal('INR'),
			z.literal('JOD'),
			z.literal('KHR'),
			z.literal('KRW'),
			z.literal('LKR'),
			z.literal('MAD'),
			z.literal('MXN'),
			z.literal('MYR'),
			z.literal('NOK'),
			z.literal('OMR'),
			z.literal('PAB'),
			z.literal('SAR'),
			z.literal('TWD'),
			z.literal('USD'),
		]),
	}),
	role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]),
	state: z.union([z.literal('PENDING'), z.literal('ACCEPTED'), z.literal('REJECTED'), z.literal('CANCELLED')]),
	inviter: z.object({
		id: z.string(),
		firstName: z.string(),
		lastName: z.string(),
	}),
});

export type OperatorRewardsUserListDto = z.infer<typeof OperatorRewardsUserListDto>;
export const OperatorRewardsUserListDto = z.object({
	users: z.array(
		z.object({
			rewardsId: z.string(),
			email: z.string(),
			firstName: z.union([z.string(), z.null(), z.undefined()]).optional(),
			lastName: z.union([z.string(), z.null(), z.undefined()]).optional(),
			troonAccessProductType: z
				.union([z.literal('TROON_ACCESS'), z.literal('TROON_ACCESS_PLUS'), z.null(), z.undefined()])
				.optional(),
			rewardsLevel: z.union([z.literal('MEMBER'), z.literal('SILVER'), z.literal('GOLD'), z.literal('PLATINUM')]),
		}),
	),
});

export type OperatorRewardsUserProfileDto = z.infer<typeof OperatorRewardsUserProfileDto>;
export const OperatorRewardsUserProfileDto = z.object({
	rewardsId: z.string(),
	accessProductType: z
		.union([z.literal('TROON_ACCESS'), z.literal('TROON_ACCESS_PLUS'), z.null(), z.undefined()])
		.optional(),
	firstName: z.union([z.string(), z.null(), z.undefined()]).optional(),
	lastName: z.union([z.string(), z.null(), z.undefined()]).optional(),
	email: z.union([z.string(), z.null(), z.undefined()]).optional(),
	phoneNumber: z.union([z.string(), z.null(), z.undefined()]).optional(),
	address: z.object({
		street: z.union([z.string(), z.null()]),
		street2: z.union([z.string(), z.null()]),
		city: z.union([z.string(), z.null()]),
		state: z.union([z.string(), z.null()]),
		country: z.union([z.string(), z.null()]),
		postalCode: z.union([z.string(), z.null()]),
	}),
	signupDate: z.string(),
	points: z.object({
		availablePoints: z.number(),
		rewardsLevel: z.union([z.literal('MEMBER'), z.literal('SILVER'), z.literal('GOLD'), z.literal('PLATINUM')]),
		ytdPoints: z.array(
			z.object({
				points: z.number(),
				year: z.number(),
			}),
		),
	}),
	programs: z.array(
		z.object({
			id: z.string(),
			name: z.string(),
		}),
	),
});

export type OperatorUpdateUserBodyDto = z.infer<typeof OperatorUpdateUserBodyDto>;
export const OperatorUpdateUserBodyDto = z.object({
	email: z.string().optional(),
	firstName: z.string().optional(),
	lastName: z.string().optional(),
	zipcode: z.string().optional(),
	city: z.string().optional(),
	state: z.string().optional(),
	country: z.string().optional(),
	phone: z.string().optional(),
});

export type AddTransactionDto = z.infer<typeof AddTransactionDto>;
export const AddTransactionDto = z.object({
	amount: z.number(),
	facilityId: z.string(),
	notes: z.union([z.string(), z.undefined()]).optional(),
	currencyCode: z.string(),
	reason: z
		.union([z.literal('CLUBS'), z.literal('CLOTHING'), z.literal('TEETIME'), z.literal('OTHER'), z.undefined()])
		.optional(),
});

export type UserRewardTransactionDto = z.infer<typeof UserRewardTransactionDto>;
export const UserRewardTransactionDto = z.object({
	id: z.string(),
	points: z.number(),
	facilityName: z.string(),
	createdAt: z.string(),
	transactionType: z.union([z.literal('AWARD'), z.literal('REDEMPTION'), z.literal('CREDIT')]),
	amount: z.object({
		value: z.string(),
		code: z.union([
			z.literal('AED'),
			z.literal('AUD'),
			z.literal('AZN'),
			z.literal('BGN'),
			z.literal('BHD'),
			z.literal('BSD'),
			z.literal('CAD'),
			z.literal('CHF'),
			z.literal('CRC'),
			z.literal('CZK'),
			z.literal('EGP'),
			z.literal('EUR'),
			z.literal('GBP'),
			z.literal('HNL'),
			z.literal('INR'),
			z.literal('JOD'),
			z.literal('KHR'),
			z.literal('KRW'),
			z.literal('LKR'),
			z.literal('MAD'),
			z.literal('MXN'),
			z.literal('MYR'),
			z.literal('NOK'),
			z.literal('OMR'),
			z.literal('PAB'),
			z.literal('SAR'),
			z.literal('TWD'),
			z.literal('USD'),
		]),
		cents: z.number(),
		displayValue: z.string(),
	}),
});

export type OperatorRedeemRewardDto = z.infer<typeof OperatorRedeemRewardDto>;
export const OperatorRedeemRewardDto = z.object({
	rewardId: z.string(),
});

export type UserRewardTransactionListDto = z.infer<typeof UserRewardTransactionListDto>;
export const UserRewardTransactionListDto = z.object({
	transactions: z.array(
		z.object({
			id: z.string(),
			points: z.number(),
			facilityName: z.string(),
			createdAt: z.string(),
			transactionType: z.union([z.literal('AWARD'), z.literal('REDEMPTION'), z.literal('CREDIT')]),
			amount: z.object({
				value: z.string(),
				code: z.union([
					z.literal('AED'),
					z.literal('AUD'),
					z.literal('AZN'),
					z.literal('BGN'),
					z.literal('BHD'),
					z.literal('BSD'),
					z.literal('CAD'),
					z.literal('CHF'),
					z.literal('CRC'),
					z.literal('CZK'),
					z.literal('EGP'),
					z.literal('EUR'),
					z.literal('GBP'),
					z.literal('HNL'),
					z.literal('INR'),
					z.literal('JOD'),
					z.literal('KHR'),
					z.literal('KRW'),
					z.literal('LKR'),
					z.literal('MAD'),
					z.literal('MXN'),
					z.literal('MYR'),
					z.literal('NOK'),
					z.literal('OMR'),
					z.literal('PAB'),
					z.literal('SAR'),
					z.literal('TWD'),
					z.literal('USD'),
				]),
				cents: z.number(),
				displayValue: z.string(),
			}),
		}),
	),
	cursor: z.union([z.string(), z.undefined()]).optional(),
});

export type OperatorFacilityAndRoleDto = z.infer<typeof OperatorFacilityAndRoleDto>;
export const OperatorFacilityAndRoleDto = z.object({
	facility: z.object({
		id: z.string(),
		name: z.string(),
		slug: z.string(),
		logo: z.union([z.string(), z.null(), z.undefined()]).optional(),
		currencyCode: z.union([
			z.literal('AED'),
			z.literal('AUD'),
			z.literal('AZN'),
			z.literal('BGN'),
			z.literal('BHD'),
			z.literal('BSD'),
			z.literal('CAD'),
			z.literal('CHF'),
			z.literal('CRC'),
			z.literal('CZK'),
			z.literal('EGP'),
			z.literal('EUR'),
			z.literal('GBP'),
			z.literal('HNL'),
			z.literal('INR'),
			z.literal('JOD'),
			z.literal('KHR'),
			z.literal('KRW'),
			z.literal('LKR'),
			z.literal('MAD'),
			z.literal('MXN'),
			z.literal('MYR'),
			z.literal('NOK'),
			z.literal('OMR'),
			z.literal('PAB'),
			z.literal('SAR'),
			z.literal('TWD'),
			z.literal('USD'),
		]),
	}),
	role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]),
});

export type OperatorSignupUserDto = z.infer<typeof OperatorSignupUserDto>;
export const OperatorSignupUserDto = z.object({
	email: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	zipcode: z.string(),
});

export type OperatorRewardsUserDto = z.infer<typeof OperatorRewardsUserDto>;
export const OperatorRewardsUserDto = z.object({
	rewardsId: z.string(),
	email: z.string(),
	firstName: z.union([z.string(), z.null(), z.undefined()]).optional(),
	lastName: z.union([z.string(), z.null(), z.undefined()]).optional(),
	troonAccessProductType: z
		.union([z.literal('TROON_ACCESS'), z.literal('TROON_ACCESS_PLUS'), z.null(), z.undefined()])
		.optional(),
	rewardsLevel: z.union([z.literal('MEMBER'), z.literal('SILVER'), z.literal('GOLD'), z.literal('PLATINUM')]),
});

export type OperatorMembershipListWithInvitesDto = z.infer<typeof OperatorMembershipListWithInvitesDto>;
export const OperatorMembershipListWithInvitesDto = z.object({
	members: z.array(
		z.object({
			id: z.string(),
			role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]),
			operator: z.object({
				id: z.string(),
				email: z.string(),
				firstName: z.union([z.string(), z.undefined()]).optional(),
				lastName: z.union([z.string(), z.undefined()]).optional(),
			}),
		}),
	),
	invites: z.array(
		z.object({
			id: z.string(),
			email: z.string(),
			createdAt: z.string(),
			expiresAt: z.string(),
			role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]),
			state: z.union([z.literal('PENDING'), z.literal('ACCEPTED'), z.literal('REJECTED'), z.literal('CANCELLED')]),
			inviter: z.object({
				id: z.string(),
				firstName: z.string(),
				lastName: z.string(),
			}),
		}),
	),
});

export type OperatorInviteMemberBodyDto = z.infer<typeof OperatorInviteMemberBodyDto>;
export const OperatorInviteMemberBodyDto = z.object({
	email: z.string(),
	role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]),
});

export type OperatorInviteDto = z.infer<typeof OperatorInviteDto>;
export const OperatorInviteDto = z.object({
	email: z.string(),
	createdAt: z.string(),
	expiresAt: z.string(),
});

export type OperatorUpdateMemberBodyDto = z.infer<typeof OperatorUpdateMemberBodyDto>;
export const OperatorUpdateMemberBodyDto = z.object({
	role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]).optional(),
});

export type OperatorFacilityMembershipDto = z.infer<typeof OperatorFacilityMembershipDto>;
export const OperatorFacilityMembershipDto = z.object({
	id: z.string(),
	role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]),
	operator: z.object({
		id: z.string(),
		email: z.string(),
		firstName: z.union([z.string(), z.undefined()]).optional(),
		lastName: z.union([z.string(), z.undefined()]).optional(),
	}),
});

export type OperatorFacilityRedemptionRewardsListDto = z.infer<typeof OperatorFacilityRedemptionRewardsListDto>;
export const OperatorFacilityRedemptionRewardsListDto = z.object({
	rewards: z.array(
		z.object({
			id: z.string(),
			course: z.string(),
			points: z.number(),
			cash: z.union([
				z.object({
					value: z.string(),
					code: z.union([
						z.literal('AED'),
						z.literal('AUD'),
						z.literal('AZN'),
						z.literal('BGN'),
						z.literal('BHD'),
						z.literal('BSD'),
						z.literal('CAD'),
						z.literal('CHF'),
						z.literal('CRC'),
						z.literal('CZK'),
						z.literal('EGP'),
						z.literal('EUR'),
						z.literal('GBP'),
						z.literal('HNL'),
						z.literal('INR'),
						z.literal('JOD'),
						z.literal('KHR'),
						z.literal('KRW'),
						z.literal('LKR'),
						z.literal('MAD'),
						z.literal('MXN'),
						z.literal('MYR'),
						z.literal('NOK'),
						z.literal('OMR'),
						z.literal('PAB'),
						z.literal('SAR'),
						z.literal('TWD'),
						z.literal('USD'),
					]),
					cents: z.number(),
					displayValue: z.string(),
				}),
				z.null(),
			]),
		}),
	),
});

export type OperatorReservationListDto = z.infer<typeof OperatorReservationListDto>;
export const OperatorReservationListDto = z.object({
	reservations: z.array(
		z.object({
			id: z.string(),
			course: z.object({
				id: z.string(),
				name: z.string(),
			}),
			users: z.array(
				z.object({
					user: z
						.union([
							z.object({
								rewardsId: z.string(),
								email: z.string(),
								firstName: z.union([z.string(), z.null(), z.undefined()]).optional(),
								lastName: z.union([z.string(), z.null(), z.undefined()]).optional(),
								troonAccessProductType: z
									.union([z.literal('TROON_ACCESS'), z.literal('TROON_ACCESS_PLUS'), z.null(), z.undefined()])
									.optional(),
								rewardsLevel: z.union([
									z.literal('MEMBER'),
									z.literal('SILVER'),
									z.literal('GOLD'),
									z.literal('PLATINUM'),
								]),
							}),
							z.null(),
						])
						.optional(),
				}),
			),
			teeTime: z.object({
				epoch: z.number(),
				tz: z.string(),
			}),
		}),
	),
});

export type OperatorMembershipFacilityListDto = z.infer<typeof OperatorMembershipFacilityListDto>;
export const OperatorMembershipFacilityListDto = z.object({
	facilities: z.array(
		z.object({
			facility: z.object({
				id: z.string(),
				name: z.string(),
				slug: z.string(),
				logo: z.union([z.string(), z.null(), z.undefined()]).optional(),
				currencyCode: z.union([
					z.literal('AED'),
					z.literal('AUD'),
					z.literal('AZN'),
					z.literal('BGN'),
					z.literal('BHD'),
					z.literal('BSD'),
					z.literal('CAD'),
					z.literal('CHF'),
					z.literal('CRC'),
					z.literal('CZK'),
					z.literal('EGP'),
					z.literal('EUR'),
					z.literal('GBP'),
					z.literal('HNL'),
					z.literal('INR'),
					z.literal('JOD'),
					z.literal('KHR'),
					z.literal('KRW'),
					z.literal('LKR'),
					z.literal('MAD'),
					z.literal('MXN'),
					z.literal('MYR'),
					z.literal('NOK'),
					z.literal('OMR'),
					z.literal('PAB'),
					z.literal('SAR'),
					z.literal('TWD'),
					z.literal('USD'),
				]),
			}),
			role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]),
		}),
	),
});

export type VisaTokenResultDto = z.infer<typeof VisaTokenResultDto>;
export const VisaTokenResultDto = z.object({
	state: z.union([z.literal('PENDING'), z.literal('VERIFIED'), z.literal('FAILED')]),
	visaStatus: z
		.union([
			z.object({
				programType: z.union([
					z.literal('VISA_INF'),
					z.literal('VISA_SIG'),
					z.literal('VISA_CAD_INF'),
					z.literal('VISA_CAD_INF_PRIV'),
				]),
				privateEligible: z.boolean(),
				expiresAt: z.string(),
				canUpgrade: z.boolean(),
			}),
			z.null(),
			z.undefined(),
		])
		.optional(),
});

export type VisaTokenDto = z.infer<typeof VisaTokenDto>;
export const VisaTokenDto = z.object({
	token: z.string(),
});

export type post_StripeController_handleWebhook = typeof post_StripeController_handleWebhook;
export const post_StripeController_handleWebhook = {
	method: z.literal('POST'),
	path: z.literal('/stripe/webhook'),
	requestFormat: z.literal('json'),
	parameters: z.never(),
	response: z.unknown(),
};

export type post_TroonWebController_handleSignup = typeof post_TroonWebController_handleSignup;
export const post_TroonWebController_handleSignup = {
	method: z.literal('POST'),
	path: z.literal('/troonweb/signup'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: TroonWebSignupDto,
	}),
	response: z.unknown(),
};

export type post_TroonWebController_handleUpdateUser = typeof post_TroonWebController_handleUpdateUser;
export const post_TroonWebController_handleUpdateUser = {
	method: z.literal('POST'),
	path: z.literal('/troonweb/user/{rewardsId}/update'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
		}),
		body: TroonWebUpdateUserDto,
	}),
	response: z.unknown(),
};

export type get_HealthController_check = typeof get_HealthController_check;
export const get_HealthController_check = {
	method: z.literal('GET'),
	path: z.literal('/health'),
	requestFormat: z.literal('json'),
	parameters: z.never(),
	response: z.object({
		status: z.string().optional(),
		info: z.union([z.unknown(), z.null()]).optional(),
		error: z.union([z.unknown(), z.null()]).optional(),
		details: z.unknown().optional(),
	}),
};

export type post_OperatorAuthController_signup = typeof post_OperatorAuthController_signup;
export const post_OperatorAuthController_signup = {
	method: z.literal('POST'),
	path: z.literal('/operator/auth/signup'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: OperatorSignupBodyDto,
	}),
	response: AuthOperatorDto,
};

export type post_OperatorAuthController_login = typeof post_OperatorAuthController_login;
export const post_OperatorAuthController_login = {
	method: z.literal('POST'),
	path: z.literal('/operator/auth/login'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: OperatorLoginBodyDto,
	}),
	response: OperatorLoginResponseDto,
};

export type post_OperatorAuthController_logout = typeof post_OperatorAuthController_logout;
export const post_OperatorAuthController_logout = {
	method: z.literal('POST'),
	path: z.literal('/operator/auth/logout'),
	requestFormat: z.literal('json'),
	parameters: z.never(),
	response: SuccessResponseDto,
};

export type post_OperatorAuthController_forgotPassword = typeof post_OperatorAuthController_forgotPassword;
export const post_OperatorAuthController_forgotPassword = {
	method: z.literal('POST'),
	path: z.literal('/operator/auth/forgot-password'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: ForgotPasswordBodyDto,
	}),
	response: ForgotPasswordDto,
};

export type post_OperatorAuthController_resetPassword = typeof post_OperatorAuthController_resetPassword;
export const post_OperatorAuthController_resetPassword = {
	method: z.literal('POST'),
	path: z.literal('/operator/auth/reset-password'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: ResetPasswordBodyDto,
	}),
	response: SuccessResponseDto,
};

export type post_OperatorAuthController_changePassword = typeof post_OperatorAuthController_changePassword;
export const post_OperatorAuthController_changePassword = {
	method: z.literal('POST'),
	path: z.literal('/operator/auth/change-password'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: ChangePasswordDto,
	}),
	response: SuccessResponseDto,
};

export type get_OperatorAuthController_me = typeof get_OperatorAuthController_me;
export const get_OperatorAuthController_me = {
	method: z.literal('GET'),
	path: z.literal('/operator/auth/me'),
	requestFormat: z.literal('json'),
	parameters: z.never(),
	response: AuthOperatorDto,
};

export type get_OperatorAuthController_invite = typeof get_OperatorAuthController_invite;
export const get_OperatorAuthController_invite = {
	method: z.literal('GET'),
	path: z.literal('/operator/auth/invite/{inviteId}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		query: z.object({
			email: z.string(),
		}),
		path: z.object({
			inviteId: z.string(),
		}),
	}),
	response: FullOperatorInviteDto,
};

export type post_OperatorAuthController_acceptInvite = typeof post_OperatorAuthController_acceptInvite;
export const post_OperatorAuthController_acceptInvite = {
	method: z.literal('POST'),
	path: z.literal('/operator/auth/invite/{inviteId}/accept'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			inviteId: z.string(),
		}),
	}),
	response: SuccessResponseDto,
};

export type get_OperatorUserController_searchUsers = typeof get_OperatorUserController_searchUsers;
export const get_OperatorUserController_searchUsers = {
	method: z.literal('GET'),
	path: z.literal('/operator/users/search'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		query: z.object({
			query: z.string(),
		}),
	}),
	response: OperatorRewardsUserListDto,
};

export type get_OperatorUserController_getUserProfile = typeof get_OperatorUserController_getUserProfile;
export const get_OperatorUserController_getUserProfile = {
	method: z.literal('GET'),
	path: z.literal('/operator/user/{rewardsId}/profile'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
		}),
	}),
	response: OperatorRewardsUserProfileDto,
};

export type post_OperatorUserController_updateUser = typeof post_OperatorUserController_updateUser;
export const post_OperatorUserController_updateUser = {
	method: z.literal('POST'),
	path: z.literal('/operator/user/{rewardsId}/update'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
		}),
		body: OperatorUpdateUserBodyDto,
	}),
	response: OperatorRewardsUserProfileDto,
};

export type post_OperatorUserController_addUserTransaction = typeof post_OperatorUserController_addUserTransaction;
export const post_OperatorUserController_addUserTransaction = {
	method: z.literal('POST'),
	path: z.literal('/operator/user/{rewardsId}/transactions/create'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
		}),
		body: AddTransactionDto,
	}),
	response: UserRewardTransactionDto,
};

export type post_OperatorUserController_redeemReward = typeof post_OperatorUserController_redeemReward;
export const post_OperatorUserController_redeemReward = {
	method: z.literal('POST'),
	path: z.literal('/operator/user/{rewardsId}/redeem'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
		}),
		body: OperatorRedeemRewardDto,
	}),
	response: SuccessResponseDto,
};

export type delete_OperatorUserController_deleteUserTransaction =
	typeof delete_OperatorUserController_deleteUserTransaction;
export const delete_OperatorUserController_deleteUserTransaction = {
	method: z.literal('DELETE'),
	path: z.literal('/operator/user/{rewardsId}/transactions/{transactionId}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
			transactionId: z.string(),
		}),
	}),
	response: SuccessResponseDto,
};

export type get_OperatorUserController_getUserTransactions = typeof get_OperatorUserController_getUserTransactions;
export const get_OperatorUserController_getUserTransactions = {
	method: z.literal('GET'),
	path: z.literal('/operator/user/{rewardsId}/transactions'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
		}),
	}),
	response: UserRewardTransactionListDto,
};

export type get_OperatorFacilityController_getFacility = typeof get_OperatorFacilityController_getFacility;
export const get_OperatorFacilityController_getFacility = {
	method: z.literal('GET'),
	path: z.literal('/operator/facility/{facilityId}/facility'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
		}),
	}),
	response: OperatorFacilityAndRoleDto,
};

export type post_OperatorFacilityController_signupUser = typeof post_OperatorFacilityController_signupUser;
export const post_OperatorFacilityController_signupUser = {
	method: z.literal('POST'),
	path: z.literal('/operator/facility/{facilityId}/user/signup'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
		}),
		body: OperatorSignupUserDto,
	}),
	response: OperatorRewardsUserDto,
};

export type get_OperatorFacilityController_getMembers = typeof get_OperatorFacilityController_getMembers;
export const get_OperatorFacilityController_getMembers = {
	method: z.literal('GET'),
	path: z.literal('/operator/facility/{facilityId}/members'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
		}),
	}),
	response: OperatorMembershipListWithInvitesDto,
};

export type post_OperatorFacilityController_inviteMember = typeof post_OperatorFacilityController_inviteMember;
export const post_OperatorFacilityController_inviteMember = {
	method: z.literal('POST'),
	path: z.literal('/operator/facility/{facilityId}/members/invite'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
		}),
		body: OperatorInviteMemberBodyDto,
	}),
	response: OperatorInviteDto,
};

export type post_OperatorFacilityController_resendInvite = typeof post_OperatorFacilityController_resendInvite;
export const post_OperatorFacilityController_resendInvite = {
	method: z.literal('POST'),
	path: z.literal('/operator/facility/{facilityId}/members/invite/{inviteId}/resend'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			inviteId: z.string(),
		}),
	}),
	response: SuccessResponseDto,
};

export type post_OperatorFacilityController_cancelInvite = typeof post_OperatorFacilityController_cancelInvite;
export const post_OperatorFacilityController_cancelInvite = {
	method: z.literal('POST'),
	path: z.literal('/operator/facility/{facilityId}/members/invite/{inviteId}/cancel'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			inviteId: z.string(),
		}),
	}),
	response: SuccessResponseDto,
};

export type post_OperatorFacilityController_updateMember = typeof post_OperatorFacilityController_updateMember;
export const post_OperatorFacilityController_updateMember = {
	method: z.literal('POST'),
	path: z.literal('/operator/facility/{facilityId}/member/{memberId}/update'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
			memberId: z.string(),
		}),
		body: OperatorUpdateMemberBodyDto,
	}),
	response: OperatorFacilityMembershipDto,
};

export type post_OperatorFacilityController_removeMember = typeof post_OperatorFacilityController_removeMember;
export const post_OperatorFacilityController_removeMember = {
	method: z.literal('POST'),
	path: z.literal('/operator/facility/{facilityId}/member/{memberId}/remove'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
			memberId: z.string(),
		}),
	}),
	response: SuccessResponseDto,
};

export type get_OperatorFacilityController_getRedemptionOptions =
	typeof get_OperatorFacilityController_getRedemptionOptions;
export const get_OperatorFacilityController_getRedemptionOptions = {
	method: z.literal('GET'),
	path: z.literal('/operator/facility/{facilityId}/redemption/rewards'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
		}),
	}),
	response: OperatorFacilityRedemptionRewardsListDto,
};

export type get_OperatorFacilityController_getReservations = typeof get_OperatorFacilityController_getReservations;
export const get_OperatorFacilityController_getReservations = {
	method: z.literal('GET'),
	path: z.literal('/operator/facility/{facilityId}/reservations'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
		}),
	}),
	response: OperatorReservationListDto,
};

export type get_OperatorFacilitiesController_getAllFacilities =
	typeof get_OperatorFacilitiesController_getAllFacilities;
export const get_OperatorFacilitiesController_getAllFacilities = {
	method: z.literal('GET'),
	path: z.literal('/operator/facilities/all'),
	requestFormat: z.literal('json'),
	parameters: z.never(),
	response: OperatorMembershipFacilityListDto,
};

export type get_VisaController_verifyVisaRequest = typeof get_VisaController_verifyVisaRequest;
export const get_VisaController_verifyVisaRequest = {
	method: z.literal('GET'),
	path: z.literal('/visa/verify/{token}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			token: z.string(),
		}),
	}),
	response: VisaTokenResultDto,
};

export type post_VisaController_getVisaToken = typeof post_VisaController_getVisaToken;
export const post_VisaController_getVisaToken = {
	method: z.literal('POST'),
	path: z.literal('/visa/token'),
	requestFormat: z.literal('json'),
	parameters: z.never(),
	response: VisaTokenDto,
};

// <EndpointByMethod>
export const EndpointByMethod = {
	post: {
		'/stripe/webhook': post_StripeController_handleWebhook,
		'/troonweb/signup': post_TroonWebController_handleSignup,
		'/troonweb/user/{rewardsId}/update': post_TroonWebController_handleUpdateUser,
		'/operator/auth/signup': post_OperatorAuthController_signup,
		'/operator/auth/login': post_OperatorAuthController_login,
		'/operator/auth/logout': post_OperatorAuthController_logout,
		'/operator/auth/forgot-password': post_OperatorAuthController_forgotPassword,
		'/operator/auth/reset-password': post_OperatorAuthController_resetPassword,
		'/operator/auth/change-password': post_OperatorAuthController_changePassword,
		'/operator/auth/invite/{inviteId}/accept': post_OperatorAuthController_acceptInvite,
		'/operator/user/{rewardsId}/update': post_OperatorUserController_updateUser,
		'/operator/user/{rewardsId}/transactions/create': post_OperatorUserController_addUserTransaction,
		'/operator/user/{rewardsId}/redeem': post_OperatorUserController_redeemReward,
		'/operator/facility/{facilityId}/user/signup': post_OperatorFacilityController_signupUser,
		'/operator/facility/{facilityId}/members/invite': post_OperatorFacilityController_inviteMember,
		'/operator/facility/{facilityId}/members/invite/{inviteId}/resend': post_OperatorFacilityController_resendInvite,
		'/operator/facility/{facilityId}/members/invite/{inviteId}/cancel': post_OperatorFacilityController_cancelInvite,
		'/operator/facility/{facilityId}/member/{memberId}/update': post_OperatorFacilityController_updateMember,
		'/operator/facility/{facilityId}/member/{memberId}/remove': post_OperatorFacilityController_removeMember,
		'/visa/token': post_VisaController_getVisaToken,
	},
	get: {
		'/health': get_HealthController_check,
		'/operator/auth/me': get_OperatorAuthController_me,
		'/operator/auth/invite/{inviteId}': get_OperatorAuthController_invite,
		'/operator/users/search': get_OperatorUserController_searchUsers,
		'/operator/user/{rewardsId}/profile': get_OperatorUserController_getUserProfile,
		'/operator/user/{rewardsId}/transactions': get_OperatorUserController_getUserTransactions,
		'/operator/facility/{facilityId}/facility': get_OperatorFacilityController_getFacility,
		'/operator/facility/{facilityId}/members': get_OperatorFacilityController_getMembers,
		'/operator/facility/{facilityId}/redemption/rewards': get_OperatorFacilityController_getRedemptionOptions,
		'/operator/facility/{facilityId}/reservations': get_OperatorFacilityController_getReservations,
		'/operator/facilities/all': get_OperatorFacilitiesController_getAllFacilities,
		'/visa/verify/{token}': get_VisaController_verifyVisaRequest,
	},
	delete: {
		'/operator/user/{rewardsId}/transactions/{transactionId}': delete_OperatorUserController_deleteUserTransaction,
	},
};
export type EndpointByMethod = typeof EndpointByMethod;
// </EndpointByMethod>

// <EndpointByMethod.Shorthands>
export type PostEndpoints = EndpointByMethod['post'];
export type GetEndpoints = EndpointByMethod['get'];
export type DeleteEndpoints = EndpointByMethod['delete'];
export type AllEndpoints = EndpointByMethod[keyof EndpointByMethod];
// </EndpointByMethod.Shorthands>

// <ApiClientTypes>
export type EndpointParameters = {
	body?: unknown;
	query?: Record<string, unknown>;
	header?: Record<string, unknown>;
	path?: Record<string, unknown>;
};

export type MutationMethod = 'post' | 'put' | 'patch' | 'delete';
export type Method = 'get' | 'head' | 'options' | MutationMethod;

type RequestFormat = 'json' | 'form-data' | 'form-url' | 'binary' | 'text';

export type DefaultEndpoint = {
	parameters?: EndpointParameters | undefined;
	response: unknown;
};

export type Endpoint<TConfig extends DefaultEndpoint = DefaultEndpoint> = {
	operationId: string;
	method: Method;
	path: string;
	requestFormat: RequestFormat;
	parameters?: TConfig['parameters'];
	meta: {
		alias: string;
		hasParameters: boolean;
		areParametersRequired: boolean;
	};
	response: TConfig['response'];
};
